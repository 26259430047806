body{
  background-color: #fff;
  width: 100%;
}

/* 

@media screen and (max-width : 1024px) {

}

@media screen and (max-width : 835px) {

}

@media screen and (max-width : 400px) {
  body{
    width: 340px;
  }
} */