.header {
    backdrop-filter: blur(1000px);
    /* background: rgba(249, 253, 255, 0.7); */
    width: 100%;
    height: 676px;
    z-index: 3;
    -webkit-backdrop-filter: blur(100px);
}

.colorOne {
    background-color: #87cede3f;
    width: 1050px;
    height: 356px;
    position: absolute;
    top: 10px;
    border-radius: 100px;
}

.colorTwo {
    background-color: #00b9b62e;
    width: 800px;
    height: 236px;
    position: absolute;
    top: 230px;
    right: 0px;
    border-radius: 100px;
}

@media screen and (max-width : 1302px) {
    .header {
        backdrop-filter: blur(500px);
        background: none;
        width: 100%;
        height: 630px;
        -webkit-backdrop-filter: blur(100px);

    }
    .colorOne {
        background-color: #5ab7cc65;
        width: 350px;
        height: 166px;
        position: absolute;
        top: 30px;
        border-radius: 100px;
    }
    
    .colorTwo {
        background-color: #00b9b658;
        width: 200px;
        height: 236px;
        position: absolute;
        top: 130px;
        right: 0px;
        border-radius: 100px;
    }
}

@media screen and (max-width : 1165px) {
    .header {
        height: 530px;
        -webkit-backdrop-filter: blur(100px);
    }
}

@media screen and (max-width : 930px) {
    .header {
        height: 480px;
        -webkit-backdrop-filter: blur(100px);
    }
}


@media screen and (max-width : 660px) {
    .header {
        -webkit-backdrop-filter: blur(100px); /* Add this line first, it fixes blur for Safari*/
        backdrop-filter: blur(100px); 
        /* backdrop-filter: none; */
        background: none;
        width: 100%;
        height: 550px;

    }
    .colorOne {
        background-color: #5ab7cc65;
        width: 250px;
        height: 306px;
        position: absolute;
        top: 40px;
        border-radius: 100px;
    }
    
    .colorTwo {
        background-color: #00b9b658;
        width: 100px;
        height: 206px;
        position: absolute;
        top: 200px;
        right: 0px;
        border-radius: 100px;
    }
}

@media screen and (max-width : 400px) {
    .header {
        -webkit-backdrop-filter: blur(100px);
        width: 100%;
        height: 560px;
        backdrop-filter: blur(100px); 
        
    }
    .colorOne {
        background-color: #5ab7cc65;
        width: 150px;
        height: 206px;
        /* display: none; */
        position: absolute;
        top: 20px;
        border-radius: 100px;
    }
    
    .colorTwo {
        background-color: #00b9b658;
        width: 150px;
        height: 156px;
        position: absolute;
        top: 300px;
        /* display: none; */

        right: 0px;
        border-radius: 100px;
    }
}