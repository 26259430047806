body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9FDFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face { 
  font-family: "InterRegular"; 
  src: url("./fonts/inter/Inter-Regular.ttf") format("truetype"); 
  font-style: normal; 
  font-weight: normal; 
} 

@font-face { 
  font-family: "InterExtraBold"; 
  src: url("./fonts/inter/Inter-ExtraBold.ttf") format("truetype"); 
  font-style: normal; 
  font-weight: normal; 
} 

@font-face { 
  font-family: "InterMedium"; 
  src: url("./fonts/inter/Inter-Medium.ttf") format("truetype"); 
  font-style: normal; 
  font-weight: normal; 
} 

@font-face { 
  font-family: "InterThin"; 
  src: url("./fonts/inter/Inter-Thin.ttf") format("truetype"); 
  font-style: normal; 
  font-weight: normal; 
} 

@font-face { 
  font-family: "InterBold"; 
  src: url("./fonts/inter/Inter-Bold.ttf") format("truetype"); 
  font-style: normal; 
  font-weight: normal; 
} 

@font-face { 
  font-family: "InterSamiBold"; 
  src: url("./fonts/inter/Inter-SemiBold.ttf") format("truetype"); 
  font-style: normal; 
  font-weight: normal; 
} 