.CoachesCard{
    border-radius: 12px;
/* big_shadow */
box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.05);
/* colors/white */
background: rgb(255, 255, 255);
/* slide */
width: 150px;
height: 222px;
/* Auto layout */
display: flex;
flex-direction: column;
justify-content: flex-start;
/* align-items: center; */
padding: 15px;
margin-bottom: 30px;
}

@media screen and (max-width : 660px) {
    .CoachesCard{
        display: flex;
align-items: center;
    justify-content: center;
    width: 200px;
    }
}