.cardsPositon {
    display: grid;
    grid-template-columns: 0.2fr 0.2fr;
    grid-template-columns: 0.2fr 0.2fr;
    gap: 25px;
    justify-content: center;
}
.schedulePosition{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.blockPostion {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabTitle {
    text-align: center;
    font-family: "InterBold";
    font-size: 32px;
}

.videosPositon {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px
}

.agressivAds {
    /* padding: 30px;
    background-color: aliceblue; */
    border-radius: 30px;
    width: 1200px;

    height: 600px;
    margin: 50px 0 50px 0;
}


.videoBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 0 0 0;
}

.videoBackground {
    background-color: #3b3b3b;
    width: 1250px;
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 0 30px
}

.titleVideo {
    color: white;
    text-align: left;
    margin-left: 50px
}

@media screen and (max-width : 1325px) {
    .schedulePosition{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .cardsPositon {
        display: flex;
        flex-direction: column;
        gap: 25px;
        justify-content: center;
        align-items: center;
    }
    .tabTitle {
        padding: 0 15px;
        font-size: 26px;
    }

    .videoBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 30px 0;
    }
    
    .videoBackground {
        background-color: #3b3b3b;
        width: 100%;
        margin: 0 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        border-radius: 20px;
        padding: 20px 15px
    }
    
    .titleVideo {
        color: white;
        text-align: left;
        margin-left: 0px;
        font-size: 22px;
    }
    .agressivAds {
        border-radius: 10px;
        width: 100%;
        height: 500px;
        margin: 10px 0 30px 0;
    }
    
}

@media screen and (max-width : 1024px) {
    .cardsPositon {
        display: flex;
        flex-direction: column;
        gap: 25px;
        justify-content: center;
        align-items: center;
    }
    .cardsPositon{
        display: flex;
    }
    .agressivAds {
        border-radius: 10px;
        width: 100%;
        height: 350px;
        margin: 10px 0 30px 0;
    }
}

@media screen and (max-width : 835px) {}

@media screen and (max-width : 660px) {
    .tabTitle {
        padding: 0 15px;
        font-size: 26px;
    }

    .videoBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 30px 0;
    }
    
    .videoBackground {
        background-color: #3b3b3b;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        padding: 0 15px;
        width: 100%;
        margin: 0 10px;
    }
    
    .titleVideo {
        color: white;
        text-align: left;
        margin-left: 0px;
        font-size: 22px;
    }
    .agressivAds {
        border-radius: 10px;
        width: 100%;
        height: 300px;
        margin: 10px 0 30px 0;
    }
}

@media screen and (max-width : 400px) {
    .tabTitle {
        padding: 0 15px;
        font-size: 26px;
    }

    .videoBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 30px 0;
    }
    
    .videoBackground {
        background-color: #3b3b3b;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        padding: 0 15px;
        width: 100%;
        margin: 0 15px;
    }
    
    .titleVideo {
        color: white;
        text-align: left;
        margin-left: 0px;
        font-size: 22px;
    }
    .agressivAds {
        border-radius: 10px;
        width: 100%;
        height: 200px;
        margin: 10px 0 30px 0;
    }
}