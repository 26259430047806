.trainersSection {
    height: 355px;
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.visitCard {
    border-radius: 24px;
    box-shadow: 0px 1.85px 3.15px 0px rgba(0, 0, 0, 0), 0px 8.15px 6.52px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.52px 25.48px 0px rgba(0, 0, 0, 0.01), 0px 64.81px 46.85px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);
    background: rgb(0, 185, 182);
    position: relative;
    width: 554px;
    height: 290px;
    padding: 50px 28px 10px;
    align-items: center;
    /* gap: 150px; */
    display: flex;
}

.productFree {
    display: flex;
    gap: 10px;
    border-radius: 0px 24px 0px 12px;
    background: rgb(255, 168, 0);
    position: absolute;
    width: 145px;
    height: 36px;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 16px;
    color: white;
    font-family: 'InterSamiBold';
    font-size: 16px;
}

.title {
    /* margin: 100px 0 0 0; */
    font-family: "InterBold";
    color: white;
    font-size: 36px;
    letter-spacing: 0;
    margin: 0;
    align-self: flex-start;
}

.listString {
    font-family: "InterRegular";
    color: white;
    font-size: 22px;
    margin: 10px;
}

.list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.BlockList {
    margin-top: 25px;
}

.linkToCourse {
    align-self: flex-end;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 30px 14px 30px;
    margin: 20px 0px;
    border-radius: 12px;
    /* btn_shadow */
    box-shadow: 0px 15px 30px 0px rgba(220, 232, 243, 0.25);
    /* colors / white */
    background: rgb(255, 255, 255);
    text-decoration: none;
    color: rgb(0, 185, 182);
    font-size: 18px;
    font-family: "InterSamiBold";
    cursor: pointer;
}

.cardsPosition {
    display: flex;
    gap: 15px;
}

.trainerCarusels {
    position: relative;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.buttonBack {
    position: absolute;
    left: -40px;
    top: 180px;
    cursor: pointer;
}

.buttonNext {
    position: absolute;
    right: -40px;
    top: 180px;
    cursor: pointer;
}

.trainerTitle {
    font-family: 'InterBold';
    font-size: 32px;
    margin: 0;
    letter-spacing: 0;
    padding: 0;
}

.slideBarSize {
    width: 620px;
}

.LittleBitTrainers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 30px
}

@media screen and (max-width : 1024px) {
    .trainersSection {
        /* margin-top: 150px; */
        height: 735px;
        display: flex;
        justify-content: center;
        gap: 50px;
        align-items: center;
        flex-direction: column;
        margin-bottom: 0;
    }
}

@media screen and (max-width : 835px) {}

@media screen and (max-width : 660px) {
    .trainersSection {
        height: fit-content;
        display: flex;
        justify-content: center;
        gap: 50px;
        align-items: center;
        margin-bottom: 0;
    }
    .linkToCourse{
        align-self: flex-end;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 20px 10px 20px;
        margin: 20px 0px;
        border-radius: 12px;
        /* btn_shadow */
        box-shadow: 0px 15px 30px 0px rgba(220, 232, 243, 0.25);
        /* colors / white */
        background: rgb(255, 255, 255);
        text-decoration: none;
        color: rgb(0, 185, 182);
        font-size: 18px;
        font-family: "InterSamiBold";
        cursor: pointer;
    }
    .visitCard {
        border-radius: 24px;
        box-shadow: 0px 1.85px 3.15px 0px rgba(0, 0, 0, 0), 0px 8.15px 6.52px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.52px 25.48px 0px rgba(0, 0, 0, 0.01), 0px 64.81px 46.85px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);
        background: rgb(0, 185, 182);
        position: relative;
        width: 314px;
        height: 200px;
        padding: 28px 25px 10px;
        display: flex;
        flex-direction: column;
    }

    .title {
        font-family: "InterBold";
        color: white;
        font-size: 23px;
        letter-spacing: 0;
        margin: 20px 0;
    }

    .listString {
        font-family: "InterRegular";
        color: white;
        font-size: 16px;
        margin: 5px;
    }

    .slideBarSize {
        padding: 0 30px;
        width: 250px;
        margin: 0 auto;
    }
    .LittleBitTrainers {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 30px;
        /* margin: 0 0 70px 0; */
    }
}