.buttonStyles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 12px 30px 14px 30px;
    border-radius: 12px;
    /* btn_shadow */
    box-shadow: 0px 15px 30px 0px rgba(220, 232, 243, 0.25);
    /* colors/gradient */
    border-radius: 12px;
    /* big_shadow */
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.05);
    /* colors / white */
    background: rgb(255, 255, 255);
    font-family: "InterRegular";
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0%;
    cursor: pointer;
}

.active {
    background: linear-gradient(225.00deg, rgb(0, 185, 182) 0%, rgb(0, 205, 172) 100%);
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.05);
    color: rgb(255, 255, 255);
}


@media screen and (max-width : 1024px) {

}

@media screen and (max-width : 835px) {}

@media screen and (max-width : 1300px) {
    .buttonStyles{
        font-size: 16px;
    }
}
