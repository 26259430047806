.cardCourses{
    /* Rectangle 2 */
width: 574px;
height: 220px;
display: flex;
gap: 15px;
align-items: center;
border-radius: 20px;
background: rgb(255, 255, 255);
box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.05);
position: relative;
padding: 10px 30px;
cursor: pointer;
}

.cardLogo{
    width: 165px; 
    height: 165px;
    border-radius: 12px;
    background-size: cover;
}
.title{
    font-family: "InterBold";
    font-size: 18px;
    width: 350px;
}

.text{
    font-family: "InterRegular";
    font-size: 18px;
    width: 350px;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    /* Включаем флексбоксы */
    -webkit-box-orient: vertical;
    /* Вертикальная ориентация */
    overflow: hidden;
}

.arow{
    position: absolute;
    right: 35px;
    bottom: 15px;
}

@media screen and (max-width : 1324px) {
    .cardCourses{
        /* Rectangle 2 */
    width: 96%;
    height: 220px;
    display: flex;
    gap: 15px;
    align-items: center;
    border-radius: 20px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    padding: 10px 30px;
    cursor: pointer;
    }
    
    .cardLogo{
        width: 165px; 
        height: 165px;
        border-radius: 12px;
        background-size: cover;
    }
    .title{
        font-family: "InterBold";
        font-size: 18px;
        width: 350px;
    }
    
    .text{
        font-family: "InterRegular";
        font-size: 18px;
        /* min-width: 30%; */
        width: 900px;
        /* max-width: 1200px; */
        -webkit-line-clamp: 4;
        display: -webkit-box;
        /* Включаем флексбоксы */
        -webkit-box-orient: vertical;
        /* Вертикальная ориентация */
        overflow: hidden;
    }
    
    .arow{
        position: absolute;
        right: 35px;
        bottom: 15px;
    }
}

@media screen and (max-width : 1173px) {
    .text{
        width: 700px;
    }
}
@media screen and (max-width : 993px) {
    .text{
        width: 500px;
    }
    .cardCourses{
        /* Rectangle 2 */
    width: 95%;
}
}
@media screen and (max-width : 771px) {
    .text{
        width: 200px;
    }
}
@media screen and (max-width : 605px) {
    .cardCourses{
        /* Rectangle 2 */
    width: 95%;
    height: 98px;
    display: flex;
    gap: 15px;
    align-items: start;
    border-radius: 20px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    padding: 20px 20px;
    }
    .title{
        font-size: 16px;
        margin: 0;
        -webkit-line-clamp: 1;
        display: -webkit-box; /* Включаем флексбоксы */
        -webkit-box-orient: vertical; /* Вертикальная ориентация */
        overflow: hidden;
        width: 200px;
    }
    
    .text{
        font-size: 16px;
        -webkit-line-clamp: 3;
        display: -webkit-box; /* Включаем флексбоксы */
        -webkit-box-orient: vertical; /* Вертикальная ориентация */
        overflow: hidden;
        margin: 0;
        width: 300px;
    }
    .cardLogo{
        width: 85px; 
        height: 85px;
        border-radius: 12px;
        background-size: cover;
        background-position: center;
    }
    .arow{
        position: absolute;
        right: 15px;
        bottom: 18px;
    }
    
}
@media screen and (max-width : 480px) {
    .cardCourses{
        /* Rectangle 2 */
    width: 95%;
    height: 98px;
    display: flex;
    gap: 15px;
    align-items: start;
    border-radius: 20px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    padding: 20px 20px;
    }
    .title{
        font-size: 16px;
        margin: 0;
        -webkit-line-clamp: 1;
        display: -webkit-box; /* Включаем флексбоксы */
        -webkit-box-orient: vertical; /* Вертикальная ориентация */
        overflow: hidden;
        width: 200px;
    }
    
    .text{
        font-size: 16px;
        -webkit-line-clamp: 3;
        display: -webkit-box; /* Включаем флексбоксы */
        -webkit-box-orient: vertical; /* Вертикальная ориентация */
        overflow: hidden;
        margin: 0;
        width: 200px;
    }
    .cardLogo{
        width: 85px; 
        height: 85px;
        border-radius: 12px;
        background-size: cover;
        background-position: center;
    }
    .arow{
        position: absolute;
        right: 15px;
        bottom: 18px;
    }
    
}

