.footerSection {
    height: 200px;
    position: relative;
    width: 100%;
    background: rgb(255, 255, 255);
    padding: 35px 0;
    display: flex;
    justify-content: center;
    gap: 470px;
}

.nonDecoration {
    text-decoration: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.logo {
    color: black;
    font-family: "InterExtraBold";
    font-size: 32px;
}

.socialBtn{
    display: flex;
    width: 500px;
    /* gap: 30px; */
    flex-wrap: wrap;
    /* grid-template-rows: 0.1fr 0.1fr; */
    /* gap: 20px; */
    margin-top: 15px;
}

.flexContact{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
    margin-right: 25px;
    width: 149px;
}

.footerLink{
    color: black;
    font-family: "InterMedium";
    font-size: 18px;
}

.footerLink:hover{
    color: #00B9B6;
}

.footerLinks{
margin-top: 60px;
display: grid;
grid-template-rows: 0.2fr 0.2fr 1fr;
grid-template-columns: 1fr .5fr 1fr;
gap: 25px;
}

@media screen and (max-width : 1265px) {
    .footerSection {
        height: 200px;
        position: relative;
        width: 100%;
        background: rgb(255, 255, 255);
        padding: 35px 0px;
        /* margin: 0 30px; */
        display: flex;
        /* align-items: center; */
        justify-content: center;
        gap: 30%;
    }
    .flexContact{
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 5px;
        margin-right: 25px;
        width: 140px;
    }
    .socialBtn{
        display: flex;
        width: 380px;
        /* gap: 30px; */
        flex-wrap: wrap;
        /* grid-template-rows: 0.1fr 0.1fr; */
        /* gap: 20px; */
        margin-top: 15px;
    }
    
}
@media screen and (max-width : 1130px) {
    .footerSection {
        height: 200px;
        position: relative;
        width: 100%;
        background: rgb(255, 255, 255);
        padding: 35px 0px;
        /* margin: 0 30px; */
        display: flex;
        /* align-items: center; */
        justify-content: center;
        gap: 22%;
    }
}
@media screen and (max-width : 1024px) {
    .footerSection {
        height: 200px;
        position: relative;
        width: 100%;
        background: rgb(255, 255, 255);
        padding: 35px 0px;
        /* margin: 0 30px; */
        display: flex;
        /* align-items: center; */
        justify-content: center;
        gap: 14%;
    }
}


@media screen and (max-width : 935px) {
    .footerSection {
        height: 150px;
        position: relative;
        width: 100%;
        background: rgb(255, 255, 255);
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: start;
        align-items: start;
        gap: 10px;
    }
    .footerLinks{
        display: none;
    }
    .socialBtn{
        /* padding: 10px 10px; */
    }
    .logo{
        padding: 15px 10px;
    }
}

@media screen and (max-width : 660px) {
    .footerSection {
        height: 150px;
        position: relative;
        width: 100%;
        background: rgb(255, 255, 255);
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .footerLinks{
        display: none;
    }
    .socialBtn{
        /* padding: 10px 10px; */
    }
    .logo{
        padding: 15px 10px;
    }
    
}